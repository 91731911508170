<template>
  <div class="flex flex-wrap" :id="id">
    <div class="w-5/6 sm:w-1/2 px-6 sm:mt-6">
      <h2 class="text-3xl text-gray-800 font-bold leading-none mb-3">{{ header }}</h2>
      <p class="text-gray-600 mb-8"><slot /></p>
    </div>
    <div class="w-full sm:w-1/2 px-6 sm:mt-6 sm:py-6">
      <image-carousel-core :dots="dots" :images="images" />
    </div>
  </div>
</template>

<script>
  import ImageCarouselCore from '@/Shared/ImageCarouselCore.vue'
  export default
  {
    props: {
      id: {
        type: String
      },
      header: {
        type: String
      },
      images: {
        type: Array
      },
      dots: {
        type: Boolean,
        default: true
      }
    },
    components: {
      ImageCarouselCore
    }
  }
</script>
